import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { logout, updatePassword } from '../redux/actions/auth';

import Alert from './layout/Alert';

const ChangePw = ( props:any ) => {
    const { auth, updatePassword, cancel } = props;

    const [oldPw, setOldPw] = useState( '' );
    const [pw1, setPw1] = useState( '' );
    const [pw2, setPw2] = useState( '' );
    const [submitting, setSubmitting] = useState( false );
    const [pwUpdated, setPwUpdated] = useState( false );

    const submitPassword = async () => {
        setSubmitting( true );
        const data = {
            user_id: auth.user_id,
            oldpw: oldPw,
            pw1: pw1,
            pw2: pw2
        }
        const res = await updatePassword( data )
        if (res) {
            setPwUpdated( true );
        }
        setSubmitting( false );
        setOldPw('');
        setPw1('');
        setPw2('');
    }

    return (
        <div className='cdc-panel member-panel pay-form'>
            <div style={{fontSize:'16px'}}>
                <h3>Change My Password</h3>
                { pwUpdated &&
                  <>
                    <div className="alert alert-success text-center">
                      <b>Password updated successfully!</b>
                    </div>
                    <div className="text-center">
                      <button className="btn btn-danger"
                              onClick={() => cancel()}
                              disabled={submitting}>Close Panel
                      </button>
                    </div>
                  </>
                }
                <Alert alertKey="setPwError" />
                {!pwUpdated &&
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>User's Email:</label>
                          <input type="text" className="form-control" disabled={true} value={auth.email}/>
                        </div>
                        <div className="form-group">
                          <label>Current Password:</label>
                          <input type="password" className="form-control" value={oldPw}
                                 onChange={(e: any) => setOldPw(e.target.value)}/>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>New Password:</label>
                          <input type="password" className="form-control" value={pw1}
                                 onChange={(e: any) => setPw1(e.target.value)}/>
                        </div>
                        <div className="form-group">
                          <label>Confirm New Password:</label>
                          <input type="password" className="form-control" value={pw2}
                                 onChange={(e: any) => setPw2(e.target.value)}/>
                        </div>
                      </div>
                    </div>
                    <div className="wb-modal-footer">
                      <button className="btn btn-success"
                              onClick={() => submitPassword()}
                              disabled={submitting}>Change Password
                      </button>
                      &nbsp; &nbsp;
                      <button className="btn btn-danger"
                              onClick={() => cancel()}
                              disabled={submitting}>Cancel
                      </button>
                    </div>
                  </form>
                }
            </div>
        </div>

    )
}

const mapStateToProps = ( state:any ) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { updatePassword })(ChangePw);


import { SET_PLANS, CLEAR_PLANS, USER_LOADED } from '../types';
import { apiRoot } from '../../services/helpers';
import axios from 'axios';

export const getPlans = () => async (dispatch:any) => {
    try {
        const res:any = await axios.get( apiRoot() + '/auth/plans');
        if (res.data.success) {
            dispatch({type: SET_PLANS, payload: res.data.plans});
        }
    } catch(err) {
        console.log('error getting plans');
    }
}

export const setGroupPlan = ( groupId:number, planId:number ) => async (dispatch:any) => {
    try {
        const data = {
            group_id: groupId,
            plan_id: planId
        }
        const res:any = await axios.put( apiRoot() + '/auth/plan', data );
        return res.data.success;

    } catch(err) {
        console.log('error getting plans');
        return false;
    }
}


import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import logo from '../../assets/img/logo.png';
import Alert from '../layout/Alert';
import { setAlert } from '../../redux/actions/alert';
import { login, logout, register } from '../../redux/actions/auth';
import { Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import HomeNewMember from '../HomeNewMember';
import { stripeKey } from '../../services/helpers';

const Register = ( props:any ) => {
    const { auth, setAlert, login, logout, register } = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');

    const [submitting, setSubmitting] = useState( false );
    const [error, setError] = useState( '' );
    const [success, setSuccess] = useState( '' );

    const stripePromise = loadStripe(stripeKey());

    const submitRegistration = async () => {
        const data = {
            lname: lname,
            fname: fname,
            email: email,
            password: password
        }
        setSubmitting( true );

        const res = await register( data );

        if (res.success) {
            setSuccess('Your account has been created successfully.');
        } else {
            setError( 'errors' );
        }
    }

    return (
        <div className='pf-login'  id="homescreen">

            <img src={logo}/>
            <div className="logo-title"><b>Penn Hills</b> Community Development Corporation</div>

            { success === '' ?
                <div className='pf-login-panel'>
                    { error !== '' &&
                        <div className="alert alert-danger">
                          <b>An error occurred creating your account</b>
                        </div>
                    }

                    <Elements stripe={stripePromise}>
                        <HomeNewMember />
                    </Elements>
                </div>
                :
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="alert alert-success">
                                <b>Registration Success!</b>
                                <br/>Your account has been created successfully. An administrator has been notified of your registration. Please await further instruction.
                                <br/>&nbsp;
                                <p><Link to={"/"}>Back to Home Page</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            }


        </div>
    )
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, login, logout, register })(Register);


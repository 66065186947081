import { combineReducers } from 'redux';
import alert from './reducers/alert';
import auth from './reducers/auth';
import profile from './reducers/profile';
import members from './reducers/members';
import plans from './reducers/plans';
import payments from './reducers/payments';

export default combineReducers({
    alert, auth, profile, plans, members, payments
});

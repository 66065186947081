import axios from 'axios';
import { apiRoot } from '../../services/helpers';

//import { setAlert} from "./alert";

import { GET_PROFILE } from "../types";

// Get the current user's profile
export const getCurrentProfile = ( email:string ) => async (dispatch:any) => {
  try {
    const res = await axios.get(apiRoot()+'/auth.php?action=profile&u='+email);

    if (res.data.success) {
      dispatch({
        type: GET_PROFILE,
        payload: res.data.profile
      })
    } else {
      console.error('getProfileError', res.data.error);
    }

  } catch(err) {
    console.error('getProfileError', err);
  }
}



// export const getCurrentProfile = ( email ) => async dispatch => {
//   try {
//     const apiUrl = apiURL();
//     const q = "{ 'EmailAddress': { '$regex': '" + email + "', '$options': '-i' } }";
//     const res = await axios.get(apiUrl+'/c/users?action=get&i=0&size=1&q='+encodeURI(q));
//
//     console.log('getProfile', res);
//
//     dispatch({
//       type: GET_PROFILE,
//       payload: res.data.Records[0]
//     })
//   } catch(err) {
//     const msg = 'An Error Occurred'; //err.response.statusText ? err.response.statusText : 'An Error Occurred';
//     const status = 'Please check console for more details'; //err.response.status ? err.response.status : 'Please check console for more details';
//
//     console.error('getProfileError', err);
//
//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: msg, status: status }
//     })
//   }
// }


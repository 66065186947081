import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../redux/actions/auth';
import { getMembers, createNewMemberPlan } from '../redux/actions/members';
import moment from 'moment';
import MemberTable from './MemberTable';
import DatePicker from 'react-datepicker';
import ChangePw from './ChangePw';

const Dashboard = (props: any) => {
    const { auth, members, logout, getMembers, createNewMemberPlan } = props;

    const newUser: any = {
        lname: '',
        fname: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: 'Penn Hills',
        state: 'PA',
        zip: '15235',
        plan_id: 0,
    }

    const [tableView, setTableView] = useState('active');
    const [newMember, setNewMember] = useState(newUser);
    const [newMemberOpen, setNewMemberOpen] = useState(false);
    const [submitting, setSubmitting] = useState( false );
    const [redirect, setRedirect] = useState( '' );
    const [error, setError] = useState( '' );

    const [changePw, setChangePw] = useState( false );

    const cancelPw = () => { setChangePw( false ); }

    useEffect(() => {
        const fetchMembers = async () => await getMembers();
        if (!members.loaded) fetchMembers();
    }, [members.loaded]);

    const year = new Date().getFullYear();
    const allMembers = [...members.list];
    const expiredMembers = allMembers.filter((m: any) => ((parseInt(m.paid_thru) < year && parseInt(m.paid_thru) > 1999) || m.paid_thru === null));
    const activeMembers = allMembers.filter((m: any) => parseInt(m.paid_thru) >= year);
    const recruitMembers = allMembers.filter((m: any) => parseInt(m.paid_thru) === 1999);

    const counts = {
        active: activeMembers.length,
        expired: expiredMembers.length,
        all: allMembers.length,
        recruits: recruitMembers.length
    }

    let tableMembers: any = [];
    if (tableView === 'active') tableMembers = activeMembers;
    if (tableView === 'expired') tableMembers = expiredMembers;
    if (tableView === 'all') tableMembers = allMembers;
    if (tableView === 'recruits') tableMembers = recruitMembers;

    const updateNewUser = ( field:string, value:string ) => {
        let tmp = { ...newMember };

        if (field === 'phone') {
            value = value.replace(/\D/g,'');
            if (value.substr(0,1) === '1') {
                value = value.substr(1);
            }
        }
        tmp[ field ] = value;
        setNewMember(tmp);
    }

    const submitNewMember = async () => {
        setSubmitting( true );
        //console.log('new member', newMember);
        const newRes:any = await createNewMemberPlan( newMember );

        if (newRes.success) {
            await getMembers();
            setSubmitting( false );
            setNewMemberOpen( false );
            setRedirect( '/member/'+newRes.member_id );
        } else {
            setSubmitting( false );
            setError( newRes.error );
        }
    }

    return (
        redirect !== '' ?
            <Redirect to={redirect}></Redirect>
            :
        <div className="dashboard">

            <h3 className="title">
                <button className="btn btn-danger btn-sm signout" onClick={() => logout()}>Sign Out</button>
                <button className="btn btn-warning btn-sm signout" onClick={()=>setChangePw(!changePw)} style={{marginRight:'12px'}}>Change Password</button>
                Member Manager
            </h3>
            { changePw &&
              <div>
                <ChangePw cancel={cancelPw} />
              </div>
            }
            <div className="breadcrumb">
                <Link to={'/'}>Home</Link> &nbsp;&nbsp;/&nbsp; Members
            </div>


            <MemberTable membersArray={tableMembers}
                         tableView={tableView}
                         changeView={setTableView}
                         setNewMemberOpen={setNewMemberOpen}
                         counts={counts}
            />

            <div className="dashboard-body">

            </div>



            <div className={'wb-modal small' + (newMemberOpen ? ' open' : '')}>
                <div className="wb-modal-wrap">
                    <div className="wb-modal-body">
                        <h3>Create Member Plan</h3>
                        { error !== '' &&
                            <div className="alert alert-danger">
                              <b>An error occurred while creating this member plan.</b>
                              <br/>{ error }
                            </div>
                        }
                        <form>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Member Plan Type</label>
                                        <select name="plan_id" value={newMember.plan_id}
                                                className="form-control" onChange={(e:any) => updateNewUser(e.target.name, e.target.value)}>
                                            <option value={0}>-- SELECT PLAN --</option>
                                            <option value={1}>Individual</option>
                                            <option value={2}>Household</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control"
                                               name="fname" value={newMember.fname}
                                               onChange={(e:any) => updateNewUser(e.target.name, e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control"
                                               name="lname" value={newMember.lname}
                                               onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="text" className="form-control"
                                               name="email" value={newMember.email}
                                               onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Phone (numeric)</label>
                                        <input type="text" className="form-control"
                                               name="phone" value={newMember.phone} maxLength={10}
                                               onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                               placeholder="4125551212"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>Address 1</label>
                                        <input type="text" className="form-control"
                                               name="address1" value={newMember.address1}
                                               onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Address 2</label>
                                        <input type="text" className="form-control"
                                               name="address2" value={newMember.address2}
                                               onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>City</label>
                                        <input type="text" className="form-control"
                                               name="city" value={newMember.city}
                                               onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>State</label>
                                        <input type="text" className="form-control"
                                               name="state" value={newMember.state} maxLength={2}
                                               onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Zip Code</label>
                                        <input type="text" className="form-control"
                                               name="zip" value={newMember.zip} maxLength={5}
                                               onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className="wb-modal-footer">
                            <button className="btn btn-success" onClick={() => submitNewMember()} disabled={submitting}>
                                Submit New Member
                            </button>
                            <button className="btn btn-danger" onClick={() => setNewMemberOpen(false)}>Cancel</button>
                        </div>

                        <div className="wb-modal-close" onClick={() => setNewMemberOpen(false)}>
                            <i className="fas fa-circle"></i>
                            <i className="far fa-times-circle"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
    members: state.members
});

export default connect(mapStateToProps, { logout, getMembers, createNewMemberPlan })(Dashboard);


import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const PrivateRoute = (props: any) => {
    const { component: Component, auth, ...rest } = props;

    const isAuthenticated = auth.isAuthenticated;

    return (
        <Route {...rest} render={props => !isAuthenticated
            ? (<Redirect to='/' />)
            : (<Component {...props} />)
        } />
    );
};

const mapStateToProps = (state: any) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);


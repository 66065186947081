import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import { apiRoot, usd, validateEmail } from '../services/helpers';
import axios from 'axios';

const Newsletter = ( props:any ) => {
    const { } = props;

    const [submitting, setSubmitting] = useState( false );
    const [success, setSuccess] = useState( -1 );
    const [payError, setPayError] = useState( '' );
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        plan_id: 1,
    });

    const formValid = () => {
        let valid = true;
        if (!formData.fname.length) {
            valid = false;  setPayError('First Name is a required field.');
        } else if (!formData.lname.length) {
            valid = false;  setPayError('Last Name is a required field.');
        } else if (!validateEmail(formData.email)) {
            valid = false;  setPayError('Please enter a valid email address.');
        }
        if (!valid) {
            setSubmitting( false );
        }
        return valid;
    }

    const submitForm = async () => {
        setSubmitting(true);
        try {
            const res1 = await axios.post( apiRoot()+'/mail/subscribe', formData );
            const userAlreadyExists = !res1.data.success;

            if (userAlreadyExists) { //
                setSuccess(1);
            } else {
                const res2 = await axios.post( apiRoot()+'/members/create', formData );
                if (res2.data.success) {
                    setSuccess(1);
                } else {
                    setSubmitting(false);
                    setPayError( 'There was an error while signing you up for the newsletter.' );
                }
            }
        } catch(err) {
            setSubmitting(false);
            setPayError( 'There was an error while signing you up for the newsletter.' );
        }
    }

    return (
        <div className='pf-login'>
            <img src={logo}/>
            <div className="logo-title"><b>Penn Hills</b> Community Development Corporation</div>

            { success !== 1 ?
                <div className='pf-login-panel'>
                    <h1>Sign Up for Our Email Newsletter</h1>
                    <p>Sign up for the PHCDC Email Newsletter and we'll send you important news, announcements, and reminders of upcoming meetings and events, directly to your inbox.<br/>&nbsp;</p>

                    <div className="form-group">
                        <label>First Name:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="far fa-user"></i></div>
                            </div>
                            <input type="text" className="form-control" value={formData.fname} placeholder="Jane"
                                   onChange={(e: any) => setFormData({ ...formData, fname: e.target.value })}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Last Name:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="far fa-user"></i></div>
                            </div>
                            <input type="text" className="form-control" value={formData.lname} placeholder="Roe"
                                   onChange={(e: any) => setFormData({ ...formData, lname: e.target.value })}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Email Address:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="far fa-envelope"></i></div>
                            </div>
                            <input type="text" className="form-control" value={formData.email}
                                   placeholder="jroe@example.com"
                                   onChange={(e: any) => setFormData({ ...formData, email: e.target.value })}/>
                        </div>
                    </div>

                    {payError !== '' &&
                      <div className="alert alert-danger" style={{ fontSize: '16px', marginTop: '24px' }}>
                        <b>{payError}</b>
                      </div>
                    }
                    <button className="btn btn-success"
                            style={{ marginTop: '16px', paddingLeft: '60px', paddingRight: '60px' }}
                            onClick={() => submitForm()}
                            disabled={submitting}>&nbsp; &nbsp; Submit &nbsp; &nbsp;</button>
                    <br/><br/>
                </div>
                :
                <div className='pf-login-panel'>
                    <div className="alert alert-success">
                        <b style={{ fontSize: '20px' }}>Signed-Up Successfully!</b>
                    </div>
                    <br/>
                    <p style={{ fontSize: '16px' }}>You have successfully signed up to receive the Penn Hills CDC Newsletter!
                        Thanks for your interest in the PHCDC!</p>

                    <br/><br/>
                    <Link to={"/"} className="btn btn-primary" style={{ paddingLeft: '40px', paddingRight: '40px' }}>Back
                        to Home</Link>
                </div>
            }

        </div>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(Newsletter);


import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import { apiRoot, usd, validateEmail } from '../services/helpers';
import axios from 'axios';

const PwReset = ( props:any ) => {
    const { } = props;

    const [submitting, setSubmitting] = useState( false );
    const [success, setSuccess] = useState( -1 );
    const [payError, setPayError] = useState( '' );
    const [formData, setFormData] = useState({
        email: ''
    });

    const submitForm = async () => {
        setSubmitting(true);
        try {
            const res:any = await axios.post( apiRoot()+'/auth/pwreset', formData );
            if (res.data.success) {
                setSuccess(1);
            }
            setSubmitting(false);

        } catch(err) {
            setSubmitting(false);
            setPayError( 'There was an error while signing you up for the PwReset.' );
        }
    }

    return (
        <div className='pf-login'>
            <img src={logo}/>
            <div className="logo-title"><b>Penn Hills</b> Community Development Corporation</div>

            { success !== 1 ?
                <div className='pf-login-panel'>
                    <h1>Reset Your Password</h1>
                    <p>Can't sign in to your account? Use this form to reset your password.</p>
                    <p>Enter your email address, and if it exists in our system you will receive an email with a special link where you can then change your password.<br/>&nbsp;</p>


                    <div className="form-group">
                        <label>Email Address:</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="far fa-envelope"></i></div>
                            </div>
                            <input type="text" className="form-control" value={formData.email}
                                   placeholder="jroe@example.com"
                                   onChange={(e: any) => setFormData({ ...formData, email: e.target.value })}/>
                        </div>
                    </div>

                    {payError !== '' &&
                      <div className="alert alert-danger" style={{ fontSize: '16px', marginTop: '24px' }}>
                        <b>{payError}</b>
                      </div>
                    }
                    <button className="btn btn-success"
                            style={{ marginTop: '16px', paddingLeft: '60px', paddingRight: '60px' }}
                            onClick={() => submitForm()}
                            disabled={submitting}>&nbsp; &nbsp; Submit &nbsp; &nbsp;</button>
                    <br/><br/>
                </div>
                :
                <div className='pf-login-panel'>
                    <div className="alert alert-success">
                        <b style={{ fontSize: '20px' }}>Email Sent!</b>
                    </div>
                    <br/>
                    <p style={{ fontSize: '16px' }}>Please check your inbox for an email from the PHCDC. This email will contain a link that will redirect you to a special form allowing you to reset your password.</p>
                    <p style={{ fontSize: '16px' }}>Having problems? Please contact PHCDC Communications Director Alan Waldron at <a
                        href="mailto:alan.waldron@pennhillscdc.org">alan.waldron@pennhillscdc.org</a></p>
                    <br/><br/>
                    <Link to={"/"} className="btn btn-primary" style={{ paddingLeft: '40px', paddingRight: '40px' }}>Back
                        to Home</Link>
                </div>
            }

        </div>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(PwReset);


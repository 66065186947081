import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT
} from '../types';
import { setAuthToken } from '../../utils/setAuthToken';

const initialState = {
    isAuthenticated: false,
    loading: true,
    token: localStorage.token ? localStorage.token : null,
    email: localStorage.email ? localStorage.email : null,
    user_id: localStorage.user_id ? localStorage.user_id : null,
    admin: 0,
    token_created: localStorage.token_created ? new Date(localStorage.token_created) : null,
    token_expires: localStorage.token_expires ? new Date(localStorage.token_expires) : null,
};

export default function( state = initialState, action:any ) {
    const { type, payload } = action;

    switch(type) {
        case USER_LOADED:
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false,
            };

        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem( 'auth_user', JSON.stringify(payload) );
            setAuthToken(payload.token);

            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false
            };

        case REGISTER_FAIL:
        case LOGIN_FAIL:
        case AUTH_ERROR:
        case LOGOUT:
            //console.log('removing stored token');
            localStorage.removeItem('auth_user');
            return {
                ...state,
                isAuthenticated: false,
                loading: true,
                token: null,
                email: null,
                user_id: null,
                admin: 0,
                token_created: null,
                token_expires: null,
            };

        default:
            return state;
    }
}

import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import logo from '../../assets/img/logo.png';

import { setAlert } from '../../redux/actions/alert';
import Alert from '../layout/Alert';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, logout } from '../../redux/actions/auth';


const Login = (props: any) => {

    const { auth, setAlert, login, logout } = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState( false );
    const [status, setStatus] = useState( '' );

    const isAuthenticated = !!(auth && auth.user_id && auth.token);
    const isAdmin = isAuthenticated && auth.admin === "1";

    return (
        isAdmin ? <Redirect to="/dashboard" /> :
        isAuthenticated ? <Redirect to="/my-account" /> :
        <div className='pf-login'>

            <img src={logo}/>
            <div className="logo-title"><b>Penn Hills</b> Community Development Corporation</div>

            <div className='pf-login-panel'>

                <h1>PHCDC Membership Manager</h1>

                <Alert alertKey="signinError"/>

                <div className="form-group">
                    <label>Email Address</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className="far fa-envelope"></i></div>
                        </div>
                        <input type="text" className="form-control" value={email}
                               onChange={(e) => setEmail(e.target.value)}/>
                    </div>

                </div>

                <div className="form-group">
                    <label>Password</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <div className="input-group-text"><i className="far fa-key"></i></div>
                        </div>
                        <input type="password" className="form-control" value={password}
                               onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                </div>

                <button className="btn btn-primary btn-block btn-lg btn-flex" onClick={() => login(email, password)}>
                    <div>Sign In</div>
                    <i className="fas fa-arrow-right"></i>
                </button>

            </div>

            <div className="more-actions">
                <Link to={'/register'} className="btn btn-opt btn-block btn-lg btn-flex">
                    <div><i className="fas fa-star fa-fw"></i> &nbsp;Become a PHCDC Member</div>
                    <i className="fas fa-arrow-right"></i>
                </Link>
                <Link to={'/'} className="btn btn-opt btn-block btn-lg btn-flex">
                    <div><i className="fas fa-donate fa-fw"></i> &nbsp;Make a Donation <span style={{color:'#990000',fontSize:'15px',fontWeight:'normal',fontStyle:'italic'}}>(temporarily disabled)</span></div>
                    <i className="fas fa-arrow-right"></i>
                </Link>
                <Link to={'/newsletter'} className="btn btn-opt btn-block btn-lg btn-flex">
                    <div><i className="fas fa-envelope fa-fw"></i> &nbsp;Sign Up for Our Email Newsletter</div>
                    <i className="fas fa-arrow-right"></i>
                </Link>
                <a href='https://pennhillscdc.org/sms' target="_blank" className="btn btn-opt btn-block btn-lg btn-flex">
                    <div><i className="fas fa-phone-alt fa-fw"></i> &nbsp;Sign Up for Text Message Alerts</div>
                    <i className="fas fa-arrow-right"></i>
                </a>
                <Link to={'/pwreset'} className="btn btn-opt btn-block btn-lg btn-flex">
                    <div><i className="fas fa-lock-alt fa-fw"></i> &nbsp;Reset Your Password</div>
                    <i className="fas fa-arrow-right"></i>
                </Link>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { setAlert, login, logout })(Login);



import { SET_ALERT, REMOVE_ALERT } from '../types';

const initialState:any = [];

export default function(state = initialState, action:any) {
    const { type, payload } = action;

    switch(type) {
        case SET_ALERT:
            return [...state, payload];
        case REMOVE_ALERT:
            //console.log('removeAlert',payload);
            return state && state.length ? state.filter((alert:any) => { return alert.key !== payload }) : [];
        default:
            return state;
    }
}

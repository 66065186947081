import { SET_ALERT, REMOVE_ALERT } from '../types';

export const setAlert = (key:string, title:string, errors:any, alertType:string) => (dispatch:any) => {
    dispatch({
        type: SET_ALERT,
        payload: { title, errors, alertType, key }
    });
};

export const removeAlert = (key:string) => (dispatch:any) => {
    dispatch({
        type: REMOVE_ALERT,
        payload: key
    });
};

import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { removeAlert } from '../../redux/actions/alert';

const AlertUI = (props: any) => {

    const [visible, setVisible] = useState(true);

    const { color, className, icon, children, alertKey, removeAlert } = props;
    let Icon;

    const closeAlert = ( key: string, event: any ) => {
        event.preventDefault();
        removeAlert( key );
    };

    switch (color) {
        case 'info':
            Icon = <i className="far fa-info-circle"></i>;
            break;
        case 'success':
            Icon = <i className="far fa-check-circle fa-lg"></i>;
            break;
        case 'warning':
            Icon = <i className="far fa-exclamation-triangle fa-lg"></i>;
            break;
        case 'danger':
            Icon = <i className="fas fa-exclamation-triangle"></i>;
            break;
        default:
            break;
    }

    return (
        <div className={"alert alert-custom alert-" + color} role="alert">
            {icon && <div className="alert-icon">{Icon}</div>}
            <div className="alert-content">
                {children}
            </div>
            <button className="close" type="button" onClick={(e:any) => closeAlert( alertKey, e )}><i className="far fa-times"></i></button>
        </div>
    );

};

export default connect(null, { removeAlert })(AlertUI);

import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addToHousehold, clearMember, getHouseholds, getMemberById, getMembers } from '../redux/actions/members';
import { logout } from '../redux/actions/auth';
import GroupTable from './GroupTable';
import PaymentsTable from './PaymentsTable';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { setGroupPlan } from '../redux/actions/plans';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { stripeKey } from '../services/helpers';

const Member = ( props:any ) => {
    const { match, auth, members, getMemberById, logout, plans, setGroupPlan, getMembers, getHouseholds, addToHousehold } = props;
    const memberId = parseInt(match.params.id);

    const [changePlan, setChangePlan] = useState( false );
    const [selectedPlan, setSelectedPlan] = useState(members.edit.loaded ? members.edit.data.plan.plan_name : 'Individual' );
    const [planSaveError, setPlanSaveError] = useState( '' );
    const [households, setHouseholds] = useState( [] );
    const [selectedHousehold, setSelectedHousehold] = useState( '' );

    const stripePromise = loadStripe(stripeKey());

    const planData = plans.plans.filter( (p:any) => p.plan_name === selectedPlan ).pop();

    const currentPlan = members.edit.loaded ? members.edit.data.plan.plan_name : '';

    const fetchMemberData = async ( memberId:number ) => await getMemberById( memberId, 'member' );

    const openPlanForm = async () => {
        let hh = await getHouseholds();
        setHouseholds( hh );
        setSelectedPlan( members.edit.loaded ? members.edit.data.plan.plan_name : 'Individual' );
        setChangePlan( true );
    }

    const savePlan = async () => {
        let saveOk:any;

        if (selectedPlan === 'ExistingHousehold') {
            saveOk = await addToHousehold( memberId, parseInt(selectedHousehold) );
        }
        else {
            saveOk = await setGroupPlan( members.edit.data.groupId, planData.plan_id );
        }

        if (saveOk) {
            await fetchMemberData( memberId );
            setPlanSaveError( '' );
            setChangePlan( false );
            await getMembers();
        }
        else {
            setChangePlan( false );
            setPlanSaveError('An error occurred while attempting to update this group\'s plan.');
        }
    }

    useEffect(() => {
        if (!members.edit.loaded || (members.edit.loaded && members.edit.memberId !== memberId)) {
            (async () => {
                await getMemberById( memberId, 'member' )
            })();
        }
    }, [])

    return ( members.edit.loaded &&
        <>
        <div className="dashboard">
            <h3 className="title">
                <button className="btn btn-danger btn-sm signout" onClick={()=>logout()}>Sign Out</button>
                Group { members.edit.loaded ? '#'+members.edit.data.groupId : ''} Details
            </h3>
            <div className="breadcrumb">
                <Link to={'/'}>Home</Link> &nbsp;&nbsp;/&nbsp;&nbsp; <Link to={'/dashboard'}>Members</Link> &nbsp;&nbsp;/&nbsp; Edit Group
            </div>
            <div className="dashboard-body">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className='cdc-panel member-panel'>
                                { members.edit.loaded && <GroupTable groupId={members.edit.data.groupId} membersArray={members.edit.data.members} isAdmin={true} /> }
                            </div>
                            <div className='cdc-panel member-panel'>
                                <h3>
                                    { changePlan ?
                                        <>
                                            <span className="red" onClick={()=>setChangePlan(false)} style={{marginLeft:'12px'}}><i className="fas fa-times-circle"></i> Cancel</span>
                                            <span className="green" onClick={()=>savePlan()}><i className="fas fa-save"></i> Save</span>
                                        </>
                                        :
                                        <span className="green" onClick={()=>openPlanForm()}><i className="fas fa-edit"></i> Change Plan</span>
                                    }
                                    Group Details
                                </h3>
                                { planSaveError &&
                                    <div className="alert alert-danger">{ planSaveError }</div>
                                }
                                <div className="member-table group-table">
                                    {members.edit.loaded &&
                                    <table className="table table-hover table-striped">
                                        { changePlan ?
                                            <tbody>
                                                <tr><td width={125}><b>Plan Name</b></td>
                                                    <td>
                                                        <select value={selectedPlan} onChange={(e:any) => setSelectedPlan(e.target.value)} className="form-control">
                                                            <option value='Individual'>Individual</option>
                                                            <option value='Household'>Household</option>
                                                            { currentPlan === 'Individual' && <option value='ExistingHousehold'>Existing Household</option> }
                                                        </select>
                                                        { selectedPlan === 'ExistingHousehold' &&
                                                            <select value={selectedHousehold} onChange={(e:any)=>setSelectedHousehold(e.target.value)} className='form-control' style={{marginTop:'10px'}}>
                                                                <option value={0}>*** Select a Household Plan ***</option>
                                                                { households.map( (hh:any) =>
                                                                    <option value={hh.group_id} key={hh.group_id}>{hh.group_id}: {hh.group_title}</option>
                                                                )}
                                                            </select>
                                                        }
                                                    </td>
                                                </tr>
                                                { selectedPlan !== 'ExistingHousehold' &&
                                                    <>
                                                    <tr><td><b>Plan Price</b></td><td>${planData.plan_price}/yr</td></tr>
                                                    <tr><td><b>Seats</b></td><td>{planData.plan_seats}</td></tr>
                                                    <tr><td><b>Paid Through</b></td><td>{members.edit.data.members[ 0 ].paid_thru}</td></tr>
                                                    </>
                                                }

                                            </tbody>
                                            :
                                            <tbody>
                                                <tr><td width={125}><b>Plan Name</b></td><td>{members.edit.data.plan.plan_name}</td></tr>
                                                <tr><td><b>Plan Price</b></td><td>${members.edit.data.plan.plan_price}/yr</td></tr>
                                                <tr><td><b>Seats</b></td><td>{ members.edit.data.members.length } / {members.edit.data.plan.plan_seats}</td></tr>
                                                <tr><td><b>Paid Through</b></td><td>{members.edit.data.members[ 0 ].paid_thru}</td></tr>
                                            </tbody>
                                        }
                                    </table>
                                    }
                                </div>
                            </div>
                        </div>
                       <div className="col-lg-6 col-md-12">
                               { members.edit.loaded &&
                                   <Elements stripe={stripePromise}>
                                        <PaymentsTable groupId={members.edit.data.groupId}
                                                       paymentsArray={members.edit.data.payments}
                                                       refreshGroup={fetchMemberData}
                                                       memberId={memberId}
                                                       isAdmin={true}
                                        />
                                   </Elements>
                               }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}


const mapStateToProps = ( state:any ) => ({
    auth: state.auth,
    members: state.members,
    plans: state.plans,
});

export default connect(mapStateToProps, { logout, getMemberById, setGroupPlan, getMembers, getHouseholds, addToHousehold })(Member);


import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { updateMember, createMember } from '../redux/actions/members';

const GroupTable = ( props:any ) => {
    const { membersArray, updateMember, createMember, groupId, isAdmin } = props;

    const init:any = {};
    const newUser:any = {
        member_id: '0',
        fname: '',
        lname: '',
        email: '',
        phone: '',
    }
    const [editOpen, setEditOpen] = useState( false );
    const [editId, setEditId] = useState( 0 );
    const [userFields, setUserFields] = useState( init );
    const [editError, setEditError] = useState( '' );

    const addMember = () => {
        let user = { ...membersArray[0], ...newUser };
        setEditId( 0 );
        setUserFields( user );
        setEditOpen( true );
    }

    const editUser = ( uid:number ) => {
        let user = membersArray.filter( (m:any) => m.member_id === uid ).pop();
        setEditId( uid );
        setUserFields( user );
        setEditOpen( true );
    }

    const editUserField = ( e:any ) => {
        e.preventDefault();
        let tmpUser = { ...userFields };
        tmpUser[e.target.name] = e.target.value;
        setUserFields( tmpUser );
    }

    const submitUser = async () => {
        let res:any = null;
        setEditError( '' );
        if ( editId === 0 ) {
            res = await createMember( userFields, parseInt(groupId) );
        } else {
            res = await updateMember( userFields );
        }
        if (res.data.success) {
            setEditOpen( false );
        } else {
            setEditError( res.data.error );
        }
    }

    return (
        <>
            <h3>
                { isAdmin && <span className="green" onClick={()=>addMember()}><i className="fas fa-plus-circle"></i> Add</span> }
                { isAdmin ? 'Group Members' : 'Plan Members' }
            </h3>

            <div className="member-table group-table">
            <table className="table table-hover table-striped">
                <thead>
                <tr>
                    { isAdmin && <th>Lead</th> }
                    <th>Name</th>
                    <th className="hide-500">Address</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {membersArray.map((m: any) =>
                    <tr key={m.member_id}>
                        { isAdmin && <td>{ m.lead === '1' ? <i className="fas fa-star"></i> : <i className="far fa-star"></i> }</td> }
                        <td className="text-nowrap">{m.lname}, {m.fname}</td>
                        <td className="hide-500">{m.address1 + (m.address2.length ? ', ' + m.address2 : '')}</td>
                        <td>
                            <i className="fas fa-edit edit-member fa-fw" onClick={()=>editUser(m.member_id)}></i>{!isAdmin ? 'EDIT' : ""}
                            { isAdmin && <a href={"mailto:"+m.email}><i className="fas fa-envelope fa-fw"></i></a> }
                            { isAdmin && <a href={"tel:"+m.phone}><i className="fas fa-phone fa-fw"></i></a> }
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        <div className={'wb-modal small' + (editOpen ? ' open' : '')}>
            <div className="wb-modal-wrap">
                <div className="wb-modal-body">
                    { editId === 0 ?
                        <h3>Add New Member to Group</h3>
                        :
                        <h3>Editing { userFields.fname } { userFields.lname }</h3>
                    }
                    <form>
                        { editError.length ?
                            <div className="alert alert-danger">
                              <b>Update Failed:</b> { editError }
                            </div>
                            : <></>
                        }
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" name="fname"
                                           value={userFields.fname} onChange={(e:any)=>editUserField(e)} />
                                </div>
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" name="lname"
                                           value={userFields.lname} onChange={(e:any)=>editUserField(e)} />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" className="form-control" name="email"
                                           value={userFields.email} onChange={(e:any)=>editUserField(e)} />
                                </div>
                                <div className="form-group">
                                    <label>Phone</label>
                                    <input type="text" className="form-control" name="phone"
                                           value={userFields.phone} onChange={(e:any)=>editUserField(e)} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Address 1</label>
                                    <input type="text" className="form-control" name="address1"
                                           value={userFields.address1} onChange={(e:any)=>editUserField(e)} />
                                </div>
                                <div className="form-group">
                                    <label>Address 2</label>
                                    <input type="text" className="form-control" name="address2"
                                           value={userFields.address2} onChange={(e:any)=>editUserField(e)} />
                                </div>
                                <div className="form-group">
                                    <label>City</label>
                                    <input type="text" className="form-control" name="city"
                                           value={userFields.city} onChange={(e:any)=>editUserField(e)} />
                                </div>
                                <div className="form-group">
                                    <label>State</label>
                                    <input type="text" className="form-control" name="state"
                                           value={userFields.state} onChange={(e:any)=>editUserField(e)} />
                                </div>
                                <div className="form-group">
                                    <label>Zip Code</label>
                                    <input type="text" className="form-control" name="zip"
                                           value={userFields.zip} onChange={(e:any)=>editUserField(e)} />
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="wb-modal-footer">
                        <button className="btn btn-success" onClick={()=>submitUser()}>Submit Changes</button>
                        <button className="btn btn-danger" onClick={()=>setEditOpen(false)}>Cancel</button>
                    </div>

                    <div className="wb-modal-close" onClick={() => setEditOpen(false)}>
                        <i className="fas fa-circle"></i>
                        <i className="far fa-times-circle"></i>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { updateMember, createMember })(GroupTable);


import React, { useState, useEffect, useCallback, useRef } from 'react';
import { RichTextEditor } from '@mantine/rte';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { apiRoot } from '../services/helpers';
import { Container } from '@mantine/core';
import { getMembers, resetEmailCount, sendEmail } from '../redux/actions/members';
import { wait } from '../services/helpers';

const EmailList = ( props:any ) => {
    const { auth, members, getMembers, sendEmail, resetEmailCount } = props;

    const used:any = [];
    const [rteContent, setRteContent] = useState( '' +
        '<p>Your content here...</p>' +
        '<p>&nbsp;</p>' +
        '<h3>Get text message updates from the PHCDC!</h3>' +
        '<p>Want to recieve your updates from the Penn Hills CDC direct to your text messages? <a href="https://pennhillscdc.org/sms">Click/Tap here to sign up...</a></p>' );
    const [emailSubject, setEmailSubject] = useState( 'PHCDC: --Subject--' );
    const [emailList, setEmailList] = useState( 'all' );
    const [status, setStatus] = useState( 'composing' );
    const [statusCount, setStatusCount] = useState( 0 );
    const [errorMsg, setErrorMsg] = useState( '' );
    const [testEmail, setTestEmail] = useState( auth.email );
    const [fromEmail, setFromEmail] = useState( 'No Reply||noreply@pennhillscdc.org' );
    const [admins, setAdmins] = useState( used );

    const usersLoaded = useRef(false);
    const adminsLoaded = useRef(false);
    const statusProgress = useRef( 0 );

    const year = new Date().getFullYear();
    const allMembers = [...members.list];
    const expiredMembers = allMembers.filter((m: any) => ((parseInt(m.paid_thru) < year && parseInt(m.paid_thru) > 1999) || m.paid_thru === null));
    const activeMembers = allMembers.filter((m: any) => parseInt(m.paid_thru) >= year);
    const recruitMembers = allMembers.filter((m: any) => parseInt(m.paid_thru) === 1999);
    const adminMembers = allMembers.filter((m: any) => parseInt(m.admin) === 1);
    const testMember = allMembers.filter((m:any) => m.email === testEmail);

    const counts:any = {
        active: activeMembers.length,
        expired: expiredMembers.length,
        all: allMembers.length,
        recruits: recruitMembers.length,
        admins: adminMembers.length,
        test: 1,
    }
    const recipients:any = {
        active: [...activeMembers],
        expired: [...expiredMembers],
        all: [...allMembers],
        recruits: [...recruitMembers],
        admins: [...adminMembers],
        test: [...testMember]
    }

    const resetForm = () => {
        //setRteContent('');
        setEmailList('all');
        setEmailSubject('PHCDC: --Subject--');
        setStatus('composing');
        setErrorMsg('');
    }

    const onSend = async () => {
        setStatus('sending');

        console.log('emailList', emailList);
        console.log('recips', recipients[emailList]);

        setStatusCount( counts[emailList] );

        recipients[emailList].map( (recip:any) => {
            const data = {
                ...recip,
                from: fromEmail,
                testEmail: testEmail,
                subject: emailSubject,
                body: rteContent
            }
            sendEmail( data );
        });
        setStatus( 'success' );
        resetEmailCount();
    }

    const sendButtonText = () => {
        let group = '';
        switch (emailList) {
            case 'all': group = 'ALL SUBSCRIBED USERS -- (' + counts[emailList] + ')' ; break;
            case 'admins': group = 'SYSTEM ADMINS -- (' + counts[emailList] + ')' ; break;
            case 'active': group = 'ACTIVE MEMBERS ONLY -- (' + counts[emailList] + ')' ; break;
            case 'expired': group = 'EXPIRED MEMBERS ONLY -- (' + counts[emailList] + ')' ; break;
            case 'recruits': group = 'RECRUITING MEMBERS ONLY -- (' + counts[emailList] + ')' ; break;
            case 'test': group = 'TEST EMAIL -- (' + counts[emailList] + ')' ; break;
        }
        return group;
    }

    const confirmSend = async () => {
        let group = '';
        let okToSend = true;

        switch (emailList) {
            case 'all': group = 'ALL SUBSCRIBED USERS'; break;
            case 'admins': group = 'SYSTEM ADMINS'; break;
            case 'active': group = 'ACTIVE MEMBERS ONLY'; break;
            case 'expired': group = 'EXPIRED MEMBERS ONLY'; break;
            case 'recruits': group = 'RECRUITING MEMBERS ONLY'; break;
            case 'test': group = 'TEST EMAIL'; break;
        }
        const ok = window.confirm('Are you sure you are ready to send this email to '+group+'? It will be sent immediately to your selected list...');

        if (rteContent === '') {
            setErrorMsg("Cannot send email, you didn't write a message");
            setStatus('error');
            okToSend = false;
        }
        if (emailList==='test' && testEmail==='') {
            setErrorMsg("If sending a test email, you must specify an address to send your test email to.");
            setStatus('error');
            okToSend = false;
        }

        if (ok && okToSend) {
            await onSend();
        }
    }

    const handleImageUpload = useCallback( (file: File): Promise<string> =>
        new Promise((resolve:any, reject:any) => {
            const formData = new FormData();
            formData.append('image', file);

            fetch('https://api.imgbb.com/1/upload?key=63d6e4967b43139d8dcf1dd03ad7b47a', {
                method: 'POST',
                body: formData,
            })
                .then((response:any) => response.json())
                .then((result:any) => resolve(result.data.url))
                .catch(() => reject(new Error('Upload failed')));
        }), []
    );

    useEffect(() => {
        if (!adminsLoaded.current) {
            (async () => {
                const res = await axios.get( apiRoot() + '/auth/admins' );
                setAdmins( res.data.admins )
            })();
            adminsLoaded.current = true;
        }
        if (!usersLoaded.current && !members.list.length){
            (async () => {
                await getMembers();
            })();
            usersLoaded.current = true;
        }
    }, [])

    return (
        <div className="dashboard">
            <h3 className="title">
                Create New Email
            </h3>

            <div className="breadcrumb">
                <Link to={'/'}>Home</Link>
                &nbsp;&nbsp;/&nbsp;&nbsp; <Link to={'/dashboard'}>Members</Link>
                &nbsp;&nbsp;/&nbsp;&nbsp; <Link to={'/email-campaigns'}>Email Campaigns</Link>
                &nbsp;&nbsp;/&nbsp;&nbsp; Create New Email
            </div>

            <div className={'container'}>
            <div className={'row'}>
                <div className={'col-md-4'}>
                    <div style={{ maxWidth:'600px', margin:'20px auto' }}>
                        <label style={{ color:'#222222'}}>Send To:</label>
                        <select className="form-control" disabled={status!=='composing'}
                                value={emailList} onChange={(e:any)=>setEmailList(e.target.value)}>
                            <option value="all">All Subscribed</option>
                            <option value="admins">System Admins</option>
                            <option value="active">Active Members</option>
                            <option value="expired">Expired Members</option>
                            <option value="recruits">Recruits</option>
                            <option value="test">Test Email</option>
                        </select>
                    </div>
                    {emailList === 'test' ?
                        <div style={{ maxWidth: '600px', margin: '20px auto' }}>
                            <label style={{ color: '#222222' }}>Send Test Email To:</label>
                            <select className="form-control" disabled={status!=='composing'}
                                    value={testEmail} onChange={(e:any)=>setTestEmail(e.target.value)}>
                                { admins.map( (admin:any) =>
                                    <option value={admin.email}>{admin.email}</option>
                                )}
                            </select>
                        </div>
                        : <></>
                    }
                    <div style={{ maxWidth:'600px', margin:'20px auto' }}>
                        <label style={{ color:'#222222'}}>From / Reply To:</label>
                        <select className="form-control" disabled={status!=='composing'}
                                value={fromEmail} onChange={(e:any)=>setFromEmail(e.target.value)}>
                            <option value="No Reply||noreply@pennhillscdc.org">No Reply</option>
                            { admins.map( (admin:any) =>
                                <option value={admin.fname + ' ' + admin.lname + '||' + admin.email }>{admin.lname}, {admin.fname}</option>
                            )}
                        </select>
                    </div>
                    <div style={{ maxWidth:'600px', margin:'20px auto' }}>
                        <label style={{ color:'#222222'}}>Email Subject:</label>
                        <input type={'text'} className={'form-control'} placeholder={'PHCDC: '} disabled={status!=='composing'}
                               value={emailSubject} onChange={(e:any)=>setEmailSubject(e.target.value)} />
                    </div>

                    <div style={{ width:'100%', marginTop:'100px', textAlign:'center'}}>

                        { status === 'success' ?
                            <>
                                <div className="alert alert-success">All emails queued successfully!<br/>You may exit the page.</div>
                                <button className={'btn btn-success'} onClick={() => resetForm()}>
                                    &nbsp;&nbsp;&nbsp;&nbsp; Reset the Form &nbsp;&nbsp;&nbsp;&nbsp;
                                </button>
                            </>
                            : status === 'error' ?
                                <>
                                    <div className="alert alert-warning">{ errorMsg }</div>
                                    <button className={'btn btn-success'} onClick={() => resetForm()}>
                                        &nbsp;&nbsp;&nbsp;&nbsp; Reset the Form &nbsp;&nbsp;&nbsp;&nbsp;
                                    </button>
                                </>
                            :
                                <>
                                <button className={'btn btn-success'} style={{width:'100%',textAlign:'center'}}
                                        onClick={() => confirmSend()} disabled={status === 'sending'}>
                                    {status === 'sending' ? 'SENDING ...' : 'Send Email? -- '+sendButtonText() }
                                </button>
                                    { status === 'sending' ?
                                        <div className="alert alert-success" style={{marginTop:'20px'}}>
                                            <b>EMAILS ARE BEING SENT!</b>
                                            <br/>{members.emailCount} of {statusCount} emails sent.
                                            <br/>DO NOT leave or refresh this page until you receive confirmation that the emails have sent.
                                        </div> : <></>
                                    }
                                </>
                        }
                    </div>

                </div>
                <div className={'col-md-8'}>
                    <div style={{ width:'100%', borderStyle:'solid', borderWidth:'1px', borderColor:'#999999', backgroundColor:'#F0F0F0' }}>

                        <div style={{ backgroundColor:'#FFFFFF', maxWidth:'600px',margin:'0 auto',fontSize:'16px',lineHeight:'1.6em' }}>
                            <div style={{ backgroundColor:'#33612a', padding:'20px', borderBottom:'solid 4px #efa72d'}}>
                                <a href={'https://pennhillscdc.org'} target={'_blank'}>
                                    <img src={'https://pennhillscdc.org/e/email-header.png'}
                                         style={{ maxWidth: '200px', display: 'block', margin: 'auto' }}/>
                                </a>
                            </div>

                            <p style={{padding:'20px 0 0 20px',fontSize:'24px',color:'#efa72d',fontWeight:'bold'}}>{emailSubject}</p>
                            <p style={{padding:'20px 0 0 20px'}}>Hi [NAME],</p>

                            <RichTextEditor value={rteContent} onChange={setRteContent} readOnly={status!=='composing'}
                                            onImageUpload={handleImageUpload}
                                            controls={[
                                                ['bold', 'italic', 'underline', 'strike'],
                                                ['h1', 'h2', 'h3'],
                                                ['unorderedList', 'orderedList'],
                                                ['link', 'image', 'video', 'blockquote'],
                                                ['alignLeft', 'alignCenter'], ['clean']
                                            ]}
                            />

                            <p style={{padding:'20px'}}>Have a great day!
                                <br/>Your friends at the Penn Hills CDC
                            </p>

                            <div style={{ backgroundColor:'#33612a', padding:'20px', borderTop:'solid 4px #efa72d'}}>
                                <a href={'https://pennhillscdc.org'} target={'_blank'}>
                                    <img src={'https://pennhillscdc.org/e/email-footer.png'}
                                         style={{ maxWidth: '180px', display: 'block', margin: 'auto' }}/>
                                </a>
                                <p style={{color:'#FFFFFF', marginTop:'12px', fontSize:'13px', textAlign:'center', lineHeight:'1.3em'}}>
                                    <b style={{color:'#efa72d'}}>Penn Hills Community Development Corporation</b>
                                    <br/>PO Box 17730
                                    <br/>Penn Hills, PA 15235
                                    <br/>
                                    <br/><u style={{color:"#FFFFFF"}}>unsubscribe</u>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>


        </div>
    )
}

const mapStateToProps = ( state:any ) => ({
    auth: state.auth,
    members: state.members
});

export default connect(mapStateToProps, { getMembers, sendEmail, resetEmailCount })(EmailList);

import { SET_PLANS, CLEAR_PLANS } from '../types';

const initialState:any = {
    plansLoaded: false,
    plans: [],
}

export default function(state = initialState, action:any) {
    const { type, payload } = action;

    switch(type) {
        case SET_PLANS:
            return {
                ...state,
                plansLoaded: true,
                plans: payload
            }

        case CLEAR_PLANS:
            return initialState;

        default:
            return state;
    }
}

import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { getPayments } from '../redux/actions/payments';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import moment from 'moment';

const PaymentsLog = ( props:any ) => {
    const { payments, getPayments } = props;

    useEffect(() => {
        if (!payments.loaded) {
            (async () => { await getPayments();  })();
        }
    }, [payments])
    
    return (
        <div className="dashboard">
            <h3 className="title">
                Payments Log
            </h3>

            <div className="breadcrumb">
                <Link to={'/'}>Home</Link> &nbsp;&nbsp;/&nbsp;&nbsp; <Link to={'/dashboard'}>Members</Link> &nbsp;&nbsp;/&nbsp;&nbsp; Payment Log
            </div>

            <div className="member-table">
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                        <th>Pay ID</th>
                        <th>Date</th>
                        <th>Members</th>
                        <th>Method</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Logged By</th>
                        <th>Notes</th>
                    </tr>
                    </thead>
                    <tbody>
                    { payments.log.map( (p:any) =>
                        <tr key={'pay-'+p.pay_id}>
                            <td>{ p.pay_id }</td>
                            <td>{ moment(p.pay_date).format('MM/DD/YYYY') }</td>
                            <td><Link to={'/member/'+p.mid}>{ p.members }</Link></td>
                            <td>{ p.pay_method }</td>
                            <td>{ p.pay_type }</td>
                            <td>${ p.pay_amount }</td>
                            <td>{ p.log_lname }</td>
                            <td>{ p.pay_details }</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = ( state:any ) => ({
    payments: state.payments
});

export default connect(mapStateToProps, { getPayments })(PaymentsLog);


import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { logout, updatePassword } from '../redux/actions/auth';
import { addToHousehold, clearMember, getHouseholds, getMemberById, getMembers } from '../redux/actions/members';
import { setGroupPlan } from '../redux/actions/plans';


import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import GroupTable from './GroupTable';
import { Elements } from '@stripe/react-stripe-js';
import PaymentsTable from './PaymentsTable';
import Alert from './layout/Alert';
import ChangePw from './ChangePw';
import { stripeKey } from '../services/helpers';

const MyAccount = ( props:any ) => {
    const { auth, members, plans, logout, addToHousehold, setGroupPlan, clearMember, getHouseholds, getMemberById, getMembers, updatePassword } = props;

    const [changePlan, setChangePlan] = useState( false );
    const [selectedPlan, setSelectedPlan] = useState(members.edit.loaded ? members.edit.data.plan.plan_name : 'Individual' );
    const [planSaveError, setPlanSaveError] = useState( '' );
    const [households, setHouseholds] = useState( [] );
    const [selectedHousehold, setSelectedHousehold] = useState( '' );
    const [memberId, setMemberId] = useState( 0 );
    const [showPwChange, setShowPwChange] = useState( false );

    const stripePromise = loadStripe(stripeKey());

    const planData = plans.plans.filter( (p:any) => p.plan_name === selectedPlan ).pop();

    const currentPlan = members.edit.loaded ? members.edit.data.plan.plan_name : '';

    const fetchMemberData = async ( memberId:number ) => {
        let res = await getMemberById(memberId, 'user');
        let mid = 0;
        res.members.map( (m:any) => {
            if (m.user_id === auth.user_id) {
                mid = m.user_id;
            }
        })
        setMemberId( mid );
    }

    const cancelPw = () => {
        setShowPwChange( false );
    }

    const openPlanForm = async () => {
        let hh = await getHouseholds();
        setHouseholds( hh );
        setSelectedPlan( members.edit.loaded ? members.edit.data.plan.plan_name : 'Individual' );
        setChangePlan( true );
    }

    const savePlan = async () => {
        let saveOk:any;

        if (selectedPlan === 'ExistingHousehold') {
            saveOk = await addToHousehold( memberId, parseInt(selectedHousehold) );
        }
        else {
            saveOk = await setGroupPlan( members.edit.data.groupId, planData.plan_id );
        }

        if (saveOk) {
            await fetchMemberData( memberId );
            setPlanSaveError( '' );
            setChangePlan( false );
            await getMembers();
        }
        else {
            setChangePlan( false );
            setPlanSaveError('An error occurred while attempting to update this group\'s plan.');
        }
    }



    useEffect(() => {
        if (!members.edit.loaded) fetchMemberData( auth.user_id );
    }, [members.edit.loaded]);

    return (
        <>
            <div className="dashboard">

                <div className="dashboard-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                            <h3 className="mytitle">
                                <div>My PHCDC Account</div>
                                <div>
                                    <button className="btn btn-warning btn-sm" onClick={()=>setShowPwChange(!showPwChange)}>Change Password</button>
                                    &nbsp;
                                    <button className="btn btn-danger btn-sm" onClick={()=>logout()}>Sign Out</button>
                                </div>
                            </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                { showPwChange &&
                                  <ChangePw cancel={cancelPw}/>
                                }

                                <div className='cdc-panel member-panel'>
                                    <a href="https://pennhillscdc.org/sms" target="_blank" style={{fontSize:'18px',color:'#222222'}}>
                                        <i className="fas fa-sms" style={{fontSize:'24px',color:'#ec7a17'}}></i>
                                        &nbsp;&nbsp;Sign up to receive text alerts from the PHCDC &nbsp;&nbsp;
                                        <i className="fas fa-chevron-double-right" style={{fontSize:'18px',color:'#ec7a17'}}></i>
                                    </a>
                                </div>

                                <div className='cdc-panel member-panel'>
                                    <h3>
                                        { changePlan ?
                                            <>
                                                <span className="red" onClick={()=>setChangePlan(false)} style={{marginLeft:'12px'}}><i className="fas fa-times-circle"></i> Cancel</span>
                                                <span className="green" onClick={()=>savePlan()}><i className="fas fa-save"></i> Save</span>
                                            </>
                                            :
                                            <>
                                            {/*<span className="green" onClick={()=>openPlanForm()}><i className="fas fa-edit"></i> Change Plan</span>*/}
                                            </>
                                        }
                                        Plan Details
                                    </h3>
                                    { planSaveError &&
                                    <div className="alert alert-danger">{ planSaveError }</div>
                                    }
                                    <div className="member-table group-table">
                                        {members.edit.loaded &&
                                        <table className="table table-hover table-striped">
                                            { changePlan ?
                                                <tbody>
                                                <tr><td width={125}><b>Plan Name</b></td>
                                                    <td>
                                                        <select value={selectedPlan} onChange={(e:any) => setSelectedPlan(e.target.value)} className="form-control">
                                                            <option value='Individual'>Individual</option>
                                                            <option value='Household'>Household</option>
                                                            { currentPlan === 'Individual' && <option value='ExistingHousehold'>Existing Household</option> }
                                                        </select>
                                                        { selectedPlan === 'ExistingHousehold' &&
                                                        <select value={selectedHousehold} onChange={(e:any)=>setSelectedHousehold(e.target.value)} className='form-control' style={{marginTop:'10px'}}>
                                                          <option value={0}>*** Select a Household Plan ***</option>
                                                            { households.map( (hh:any) =>
                                                                <option value={hh.group_id} key={hh.group_id}>{hh.group_id}: {hh.group_title}</option>
                                                            )}
                                                        </select>
                                                        }
                                                    </td>
                                                </tr>
                                                { selectedPlan !== 'ExistingHousehold' &&
                                                <>
                                                  <tr><td><b>Plan Price</b></td><td>${planData.plan_price}/yr</td></tr>
                                                  <tr><td><b>Seats</b></td><td>{planData.plan_seats}</td></tr>
                                                  <tr><td><b>Paid Through</b></td><td>{members.edit.data.members[ 0 ].paid_thru === '1999' ? '-' : members.edit.data.members[ 0 ].paid_thru}</td></tr>
                                                </>
                                                }

                                                </tbody>
                                                :
                                                <tbody>
                                                <tr><td width={125}><b>Plan Name</b></td><td>{members.edit.data.plan.plan_name}</td></tr>
                                                <tr><td><b>Plan Price</b></td><td>${members.edit.data.plan.plan_price}/yr</td></tr>
                                                { members.edit.data.plan.plan_name !== 'Individual' &&
                                                    <tr><td><b>Seats</b></td><td>{ members.edit.data.members.length } / {members.edit.data.plan.plan_seats}</td></tr>
                                                }
                                                <tr><td><b>Paid Through</b></td><td>{members.edit.data.members[ 0 ].paid_thru === '1999' ? '-' : members.edit.data.members[ 0 ].paid_thru}</td></tr>
                                                </tbody>
                                            }
                                        </table>
                                        }
                                    </div>

                                    <br/>
                                    { members.edit.loaded && <GroupTable groupId={members.edit.data.groupId} membersArray={members.edit.data.members} isAdmin={false} /> }

                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">

                                    { members.edit.loaded &&
                                    <Elements stripe={stripePromise}>
                                      <PaymentsTable groupId={members.edit.data.groupId}
                                                     paymentsArray={members.edit.data.payments}
                                                     refreshGroup={fetchMemberData}
                                                     memberId={memberId}
                                                     userId={auth.user_id}
                                                     isAdmin={false}
                                      />
                                    </Elements>
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ( state:any ) => ({
    auth: state.auth,
    members: state.members,
    plans: state.plans,
});

export default connect(mapStateToProps, { logout, setGroupPlan, addToHousehold, clearMember, getHouseholds, getMemberById, getMembers, updatePassword })(MyAccount);


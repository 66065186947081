import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { apiRoot, CARD_ELEMENT_OPTIONS, checkVotingDate, validateEmail, yearApplied } from '../services/helpers';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ok } from 'assert';

const HomeNewMember = ( props:any ) => {
    const { } = props;

    const newUser: any = {
        lname: '',
        fname: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: 'Penn Hills',
        state: 'PA',
        zip: '15235',
        plan_id: '1',
        password: ''
    }
    const [newMember, setNewMember] = useState(newUser);
    const [submitting, setSubmitting] = useState( false );
    const [payError, setPayError] = useState( '' );
    const [acctExists, setAcctExists] = useState( false );
    const [viewPw, setViewPw] = useState( false );
    const [success, setSuccess] = useState( '' );

    const memberYear = yearApplied();
    const stripe = useStripe();
    const elements = useElements();

    const updateNewUser = ( field:string, value:string ) => {
        let tmp = { ...newMember };
        console.log(field, value);

        if (field === 'phone') {
            value = value.replace(/\D/g,'');
            if (value.substr(0,1) === '1') {
                value = value.substr(1);
            }
        }
        tmp[ field ] = value;
        setNewMember(tmp);
    }

    const resetForm = () => {
        setNewMember( newUser );
        setSubmitting( false );
        setPayError( '' );
    }

    const formValid = () => {
        let valid = true;
        if (!newMember.lname.length) {
            valid = false;  setPayError('Last Name is a required field.');
        } else if (!newMember.fname.length) {
            valid = false;  setPayError('First Name is a required field.');
        } else if (!validateEmail(newMember.email)) {
            valid = false;  setPayError('Please enter a valid email address.');
        } else if (!newMember.phone.length) {
            valid = false;  setPayError('Phone Number is a required field.');
        } else if (!newMember.address1.length) {
            valid = false;  setPayError('Address 1 is a required field.');
        } else if (!newMember.city.length) {
            valid = false;  setPayError('City is a required field.');
        } else if (!newMember.state.length) {
            valid = false;  setPayError('State is a required field.');
        } else if (!newMember.zip.length) {
            valid = false;  setPayError('Zip Code is a required field.');
        }
        if (!valid) {
            setSubmitting( false );
        }
        return valid;
    }

    const confirmUserAccount = async () => {
        setAcctExists( false );
        let exists:any = await axios.post(apiRoot() + '/members/exists', newMember);
        if (!exists.data.success) {
            setAcctExists( true );
        } else {
            getStripeToken();
        }
    }

    const getStripeToken = async () => {
        setAcctExists( false );
        if (!stripe || !elements) { return; }
        setSubmitting( true );
        setPayError('');

        const ok2pay = formValid();

        if (ok2pay) {
            const card:any = elements.getElement(CardElement);
            const result:any = await stripe.createToken(card);

            if (result.error) {
                // Show error to your customer.
                console.log(result.error.message);
            } else {
                // Send the token to your server.
                let emailReceipt = '';

                const metadata:any = {
                    Type: 'New Membership',
                    Plan: newMember.plan_id==='1' ? 'Individual' : 'Household',
                    Price: newMember.plan_id==='1' ? '$20' : '$40',
                    Members: newMember.fname+' '+newMember.lname,
                    Notes: ''
                }

                const paymentData = {
                    token: result.token.id,
                    amount: newMember.plan_id==='1' ? 2000 : 4000,
                    metadata: metadata,
                    email: newMember.email,
                    description: 'New PHCDC Membership payment for ' + yearApplied()
                };

                try {
                    const res:any = await axios.post( apiRoot()+'/payments/charge', paymentData);
                    console.log('payRes', res);
                    if (res.data.success) {
                        await submitNewAccount( res.data.chargeResponse );
                    }
                } catch(err) {
                    setSubmitting(false);
                    setPayError( 'There was an error processing this credit card.' );
                }

            }
        }
    }

    const submitNewAccount = async ( chargeData:any = {} ) => {
        setSubmitting( true );

        const data = {
            group_id: null,
            user_id: null,
            pay_date: parseInt(moment().format('YYYYMMDD')),
            pay_amount: newMember.plan_id==='1' ? 20.00 : 40.00,
            pay_method: 'credit',
            pay_type: 'newMembership',
            logged_by: null,
            pay_details: '',
            pay_data: chargeData,
            year_applied: yearApplied(),
            new_user: newMember
        }

        try {
            let payRes:any = await axios.post(apiRoot() + '/members/account', data);
            if (payRes.data.success) {
                // await createMembership();
                setSubmitting(false);
                setSuccess( newMember.plan_id );
                resetForm();
            } else {
                setSubmitting(false);
                setPayError( payRes.data.errors[0] );
            }

        } catch(err:any) {
            setSubmitting(false);
            setPayError( 'There was an error submitting your payment.'+err['errors'][0] );
        }
    }

    return (
        <div className="member-form">

            <div className="row">
                <div className="col-sm-12">
                    <h4>New Member Registration</h4>
                    {/*<div className="alert alert-warning" style={{ fontSize: '16px', marginTop:'24px' }}>*/}
                    {/*    <b>New Members Only!</b>*/}
                    {/*    <br/>If you are, or have ever been a PHCDC member then DO NOT use this form. You already have an account.*/}
                    {/*    Try using the <Link to={"/pwreset"}>"Password Reset"</Link> form and enter the email address from which you receive*/}
                    {/*    emails from us.*/}
                    {/*</div>*/}
                </div>
            </div>

            { success !== '' ?
                <>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="alert alert-success" style={{ fontSize: '16px' }}>
                                <b>Registration Successful!</b>
                                { success === '1' ?
                                    <><br/>You may now sign in to your account using the email address and password you specified.</>
                                    :
                                    <><br/>You may now sign in to your account using the email address and password you specified. There you can add the remaining members of your household.</>
                                }
                            </div>
                            <br/>
                            <Link to={'/login'} className="btn btn-success">&nbsp; &nbsp; Sign In &nbsp; &nbsp;</Link>
                            <br/><br/>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="form-group check-choices">
                        <h5>Choose a plan:</h5>
                        <div className={"check-choice"+(newMember.plan_id==='1'?' selected':'')}
                             onClick={() => updateNewUser('plan_id', '1')}
                        >
                            <i className={"fa-circle"+(newMember.plan_id==='1'?' fas':' far')}></i>
                            <div><b>Individual &nbsp; &nbsp; ($20/year)</b>
                                <br/>Choose this option if you are joining by yourself.
                            </div>
                        </div>
                        <div className={"check-choice"+(newMember.plan_id==='2'?' selected':'')}
                             onClick={() => updateNewUser('plan_id', '2')}
                        >
                            <i className={"fa-circle"+(newMember.plan_id==='2'?' fas':' far')}></i>
                            <div><b>Household &nbsp; &nbsp; ($40/year)</b>
                                <br/>Choose this option if more than one person in your household will be a member. Other members can be added once your registration is complete.
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            { newMember.plan_id === '1' ? <h5>Your Information:</h5> : <h5>Primary Member Info:</h5> }
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>First Name</label>
                                <input type="text" className="form-control"
                                       name="fname" value={newMember.fname}
                                       onChange={(e:any) => updateNewUser(e.target.name, e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Last Name</label>
                                <input type="text" className="form-control"
                                       name="lname" value={newMember.lname}
                                       onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="text" className="form-control"
                                       name="email" value={newMember.email}
                                       onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Phone (numeric)</label>
                                <input type="number" className="form-control"
                                       name="phone" value={newMember.phone} maxLength={10}
                                       onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                       placeholder="4125551212"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Address 1</label>
                                <input type="text" className="form-control"
                                       name="address1" value={newMember.address1}
                                       onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Address 2</label>
                                <input type="text" className="form-control"
                                       name="address2" value={newMember.address2}
                                       onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label>City</label>
                                <input type="text" className="form-control"
                                       name="city" value={newMember.city}
                                       onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>State</label>
                                <input type="text" className="form-control"
                                       name="state" value={newMember.state} maxLength={2}
                                       onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label>Zip Code</label>
                                <input type="text" className="form-control"
                                       name="zip" value={newMember.zip} maxLength={5}
                                       onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <br/>
                            <h5>Account Security:</h5>
                            <label>Create Account Password:</label>
                            <div className="input-group mb-3">
                                <input type={ viewPw ? 'text' : 'password'} className="form-control"
                                       name="password" value={newMember.password}
                                       onChange={(e: any) => updateNewUser(e.target.name, e.target.value)}
                                />
                                <span className="input-group-text">
                            <i className={"fas fa-fw " + ( viewPw ? "fa-eye-slash" : "fa-eye")}
                               onClick={()=>setViewPw( !viewPw )}
                            />
                        </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <br/>
                            <h5>Payment Information:</h5>
                            <div className="form-group">
                                <label>Credit Card</label>
                                <div className="form-control" style={{padding:'8px 14px 20px',height:'40px'}}>
                                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <p>By clicking the Submit Payment button, you authorize the Penn Hills CDC to charge your credit
                            card in the amount of { newMember.plan_id === '1' ? '$20.00' : '$40.00 '}. Charges will appear on your
                            statement as PENNHILLSCDC.</p>

                            {payError !== '' &&
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="alert alert-danger" style={{ fontSize: '16px', marginTop:'24px' }}>
                                    <b>{payError}</b>
                                  </div>
                                </div>
                              </div>
                            }

                            {acctExists &&
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="alert alert-danger" style={{ fontSize: '16px', marginTop:'24px' }}>
                                    <b>Account Already Exists</b>
                                    <br/>It appears an account already exists for this member. Try using the <Link to={"/pwreset"}>"Password Reset"</Link> form to recover your password.
                                  </div>
                                </div>
                              </div>
                            }

                            <button className="btn btn-success"
                                    style={{marginTop:'16px',paddingLeft:'20px',paddingRight:'20px'}}
                                    onClick={()=>confirmUserAccount()}
                                    disabled={submitting}>&nbsp; &nbsp; Submit Payment &nbsp; &nbsp;</button>
                            <br/><br/>
                        </div>
                    </div>
                </>
            }



        </div>
    )
}

const mapStateToProps = ( state:any ) => ({

});

export default connect(mapStateToProps, { })(HomeNewMember);


import { GET_MEMBER_DATA, GET_MEMBERS, CLEAR_MEMBER, GET_PAYMENTS, INCR_EMAIL_COUNT, RESET_EMAIL_COUNT } from '../types';
import axios from 'axios';
import { apiRoot } from '../../services/helpers';

export const sendEmail = ( data:any ) => async (dispatch:any) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    console.log('data', data);
    const res = await axios.post(apiRoot() + '/mail/sendone', data, config);
    dispatch({
        type: INCR_EMAIL_COUNT,
        payload: null
    });
}

export const resetEmailCount = () => async (dispatch:any) => {
    dispatch({
        type: RESET_EMAIL_COUNT,
        payload: null
    });
}

export const getMembers = () => async (dispatch:any) => {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        const res = await axios.get(apiRoot() + '/members/list', config);
        if (res.data.members && res.data.members.length) {
            dispatch({
                type: GET_MEMBERS,
                payload: res.data.members
            });
        }

    } catch(err) {
        console.log("Error getting user list");
    }
};


export const getMemberById = ( id:string, method:string ) => async (dispatch:any) => {
    console.log('getting member by id', id);
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        const res = await axios.get(apiRoot() + '/members/detail/'+id+'/'+method, config);
        if (res.data && res.data.members.length) {
            const payload = {
                members: res.data.members,
                payments: res.data.payments,
                groupId: parseInt(res.data.groupId),
                memberId: parseInt( id ),
                plan: res.data.plan
            };
            dispatch({
                type: GET_MEMBER_DATA,
                payload: payload
            });
            return payload;
        }

    } catch(err) {
        console.log("Error getting member data");
    }
}


export const updateMember = ( data:any ) => async (dispatch:any) => {
    try {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const res = await axios.put(apiRoot() + '/members/member/'+data.member_id, data, config);
        if (res.data.success) {
            dispatch(getMembers());
            dispatch(getMemberById( data.member_id, 'member' ));
            return res;
        } else {
            return res;
        }
    } catch(err) {
        console.log("Error updating member data", err);
        return {
            success: false,
            error: err
        };
    }
}


export const createMember = ( data:any, groupId:number ) => async (dispatch:any) => {
    try {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const res = await axios.post(apiRoot() + '/members/member/'+groupId, data, config);
        if (res.data.success) {
            dispatch(getMembers());
            dispatch(getMemberById( res.data.member_id, 'member' ));
            return res;
        } else {
            return res;
        }
    } catch(err) {
        console.log("Error creating new member");
        return {
            success: false,
            error: err
        };
    }
}


export const clearMember = () => async (dispatch:any) => {
    dispatch({ type: CLEAR_MEMBER, payload:null });
}


export const getHouseholds = () => async (dispatch:any) => {
    try {
        const res = await axios.get(apiRoot() + '/members/households');
        if (res.data.success) {
            return res.data.households;
        } else {
            return [];
        }
    } catch(err) {
        console.log("Error fetching households");
        return [];
    }
}


export const addToHousehold = ( memberId:number, groupId:number ) => async (dispatch:any) => {
    try {
        const data = {
            member_id: memberId,
            group_id: groupId
        };
        const res = await axios.put(apiRoot() + '/members/household', data);
        return res.data.success;

    } catch(err) {
        console.log("Error fetching households");
        return false;
    }
}


export const createNewMemberPlan = ( data:any )  => async (dispatch:any) => {
    try {
        //const res = await axios.post(apiRoot() + '/members/plan', data);
        const res = await axios.post(apiRoot() + '/members/create', data);
        return res.data;

    } catch(err) {
        console.log("Error fetching households");
        return {
            success: false,
        };
    }
}

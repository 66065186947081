import { GET_MEMBERS, GET_MEMBER_DATA, CLEAR_MEMBER, INCR_EMAIL_COUNT, RESET_EMAIL_COUNT } from '../types';
import { stat } from 'fs';

const initialState:any = {
    list: [],
    edit: {
        loaded: false,
        data: {}
    },
    loaded: false,
    emailCount: 0
};

export default function(state = initialState, action:any) {
    const { type, payload } = action;

    switch(type) {
        case GET_MEMBERS:
            return {
                ...state,
                list: payload,
                loaded: true
            };

        case GET_MEMBER_DATA:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    loaded: true,
                    data: payload
                }
            }

        case CLEAR_MEMBER:
            return initialState;

        case INCR_EMAIL_COUNT:
            return {
                ...state,
                emailCount: state.emailCount + 1,
            }

        case RESET_EMAIL_COUNT:
            return {
                ...state,
                emailCount: 0,
            }

        default:
            return state;
    }
}


export const localSave = async ( key:string, value:string ) => {
    try {
        await localStorage.setItem(key, value);
        return true;

    } catch (error) {
        // Error saving data
        return false;
    }
};


export const localGet = async ( key:string ) => {
    try {
        return await localStorage.getItem( key );

    } catch (error) {
        // Error retrieving data
        return null;
    }
};


export const localClear = async ( key:string ) => {
    try {
        await localStorage.removeItem( key );
        return true;

    } catch (error) {
        // Error retrieving data
        return false;
    }
};


export const localClearAll = async () => {
    try {
        await localStorage.removeItem( 'userId' );
        await localStorage.removeItem( 'token' );
        await localStorage.removeItem( 'tokenExpires' );
        await localStorage.removeItem( 'qbToken' );
        return true;

    } catch (error) {
        // Error retrieving data
        return false;
    }
}

import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AlertComponent from './AlertUI';

const Alert = (props: any) => {
    const { alert, alertKey } = props;

    let thisAlert = [];
    if (alert && alert.length > 0) {
        thisAlert = alert.filter((al: any) => {
            return al.key === alertKey
        });
        if (thisAlert.length) thisAlert = thisAlert[ 0 ];
    }

    return (
        thisAlert && (thisAlert.title || thisAlert.errors && thisAlert.errors.length) ?
            <AlertComponent color={thisAlert.alertType} icon={true} className="alert--bordered" alertKey={thisAlert.key}>
                {thisAlert.title.length > 0 && <strong>{thisAlert.title}</strong>}
                {thisAlert.errors.map((err: any) => <div key={alertKey}>{err}</div>)}
            </AlertComponent>
            : <></>
    )
};

const mapStateToProps = (state: any, ownProps?: any) => ({
    alert: state.alert,
    alertKey: ownProps.alertKey,
});

export default connect(mapStateToProps)(Alert);


import { CLEAR_PAYMENTS, GET_PAYMENTS } from '../types';

const initialState:any = {
    log: [],
    loaded: false
};

export default function(state = initialState, action:any) {
    const { type, payload } = action;

    switch(type) {
        case GET_PAYMENTS:
            return {
                ...state,
                log: payload,
                loaded: true
            };
        case CLEAR_PAYMENTS:
            return initialState;

        default:
            return state;
    }
}

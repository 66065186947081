import { GET_PAYMENTS } from '../types';
import axios from 'axios';
import { apiRoot } from '../../services/helpers';

export const getPayments = () => async (dispatch:any) => {
    try {
        const config = {
            headers: { 'Content-Type': 'application/json' }
        };
        const res = await axios.get(apiRoot() + '/payments/log', config);
        if (res.data.payments && res.data.payments.length) {
            dispatch({
                type: GET_PAYMENTS,
                payload: res.data.payments
            });
        }

    } catch(err) {
        console.log("Error getting user list");
    }
};

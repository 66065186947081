import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CARD_ELEMENT_OPTIONS, yearApplied, ucwords, validateEmail, checkVotingDate } from '../services/helpers';
import axios from 'axios';
import { apiRoot } from '../services/helpers';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { getMembers } from '../redux/actions/members';


const PaymentsTable = (props: any) => {
    const { groupId, paymentsArray, auth, members, refreshGroup, memberId, getMembers, isAdmin, userId } = props;

    const [payModalOpen, setPayModalOpen] = useState( false );
    const [payMethod, setPayMethod] = useState( isAdmin ? 'cash' : 'credit' );
    const [payType, setPayType] = useState( 'renewal' );
    const [payAmt, setPayAmt] = useState( members.edit.data.plan.plan_price );
    const [notes, setNotes] = useState( '' );
    const [date, setDate] = useState(new Date());
    const [submitting, setSubmitting] = useState( false );
    const [success, setSuccess] = useState( false );
    const [payError, setPayError] = useState( '' );

    const memberYear = yearApplied();
    const stripe = useStripe();
    const elements = useElements();

    const resetForm = () => {
        setPayMethod('cash');
        setPayType('renewal');
        setPayAmt( members.edit.data.plan.plan_price );
        setNotes( '' );
        setDate( new Date() );
        setSubmitting( false );
        setPayError( '' );
    }

    const getStripeToken = async () => {
        if (!stripe || !elements) { return; }
        setSubmitting( true );

        const card:any = elements.getElement(CardElement);
        const result:any = await stripe.createToken(card);

        if (result.error) {
            // Show error to your customer.
            console.log(result.error.message);
        } else {
            // Send the token to your server.
            // This function does not exist yet; we will define it in the next step.
            let planMembers = '';
            let emailReceipt = '';
            members.edit.data.members.map( (m:any, i:number) => {
                if (i>0) planMembers += ', ';
                planMembers += m.fname+' '+m.lname;
                if ( m.lead === '1' ) {
                    emailReceipt = m.email;
                }
            });

            const metadata:any = payType === 'renewal' ? {
                Type: 'Membership Renewal',
                Plan: members.edit.data.plan.plan_name,
                Price: members.edit.data.plan.plan_price,
                Members: planMembers,
                Notes: notes
            } : {
                Type: 'Donation',
                Donors: planMembers,
                Amount: '$'+payAmt,
                Notes: notes,
            }

            const paymentData = {
                token: result.token.id,
                amount: parseInt(payAmt) * 100,
                metadata: metadata,
                email: emailReceipt,
                description: payType === 'renewal' ?
                    'PHCDC Membership ' + payType + ' payment for ' + yearApplied()
                    :
                    'PHCDC Donation'
            };

            try {
                const res:any = await axios.post( apiRoot()+'/payments/charge', paymentData);
                console.log('payRes', res);
                if (res.data.success) {
                    await submitPayment( res.data.chargeResponse );
                }
            } catch(err) {
                setSubmitting(false);
                setPayError( 'There was an error processing this credit card.' );
            }

        }
    }

    const submitPayment = async ( chargeData:any = {} ) => {
        setSubmitting( true );

        const data = {
            group_id: parseInt(members.edit.data.groupId),
            user_id: parseInt(auth.user_id),
            pay_date: parseInt(moment(date).format('YYYYMMDD')),
            pay_amount: parseFloat(payAmt),
            pay_method: payMethod,
            pay_type: payType,
            logged_by: parseInt(auth.user_id),
            pay_details: notes,
            pay_data: chargeData,
            year_applied: yearApplied()
        }

        console.log('data', data);

        try {
            let payRes:any = await axios.post(apiRoot() + '/payments/renewal', data);
            if (payRes.data.success) {
                await refreshGroup( userId );
                await getMembers();
                resetForm();
                setSubmitting(false);
                setSuccess(true);
                setPayModalOpen( false );
            } else {
                setSubmitting(false);
                setPayError( payRes.data.errors[0] );
            }

        } catch(err) {
            setSubmitting(false);
            setPayError( 'There was an error submitting your payment.' );
        }
    }

    const changeMethod = ( method:string ) => {
        if (method === 'renewal') {
            setPayAmt( members.edit.data.plan.plan_price );
        } else {
            setPayAmt( '0.00' );
        }
        setPayType( method );
    }

    return (
        <>
            <div className='cdc-panel member-panel'>
            <h3>
                Payments
            </h3>
            <div className="member-table group-table">
                <table className="table table-hover table-striped">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th className="hide-500">Logged By</th>
                    </tr>
                    </thead>
                    <tbody>
                    {paymentsArray.map((p: any) =>
                        <tr key={p.pay_id}>
                            <td>{ moment(p.pay_date).format('MM/DD/YYYY')}</td>
                            <td>${p.pay_amount}</td>
                            <td>{ ucwords(p.pay_method) } { ucwords(p.pay_type) }</td>
                            <td className="hide-500">{p.fname.substring(0,1).toLowerCase()}{p.lname.toLowerCase()}</td>
                        </tr>
                    )}
                    { !paymentsArray.length &&
                        <tr>
                          <td colSpan={4} className="text-center">
                            <br/><b>No payment data to display</b><br/>&nbsp;
                          </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
            </div>

            <div className='cdc-panel member-panel'>
            <div className={'pay-form'}>
                <div className="">
                    <div className="">
                        <h3>Submit Payment</h3>
                        {success ?
                            <>
                                <div className="alert alert-success" style={{ fontSize: '16px' }}>
                                    <b>Payment Successful!</b>
                                    <br/>Please check your email for payment receipt.
                                </div>
                                <button className="btn btn-primary" onClick={()=>window.location.reload()}>Submit Another Payment</button>
                            </>
                            :
                            <div>
                                {payError !== '' &&
                                  <div className="alert alert-danger"
                                       style={{ fontSize: '16px', position: 'relative', top: '-12px' }}>
                                    <b>{payError}</b>
                                  </div>
                                }

                                <form>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Payment Date</label>
                                                <DatePicker selected={date}
                                                            className="form-control"
                                                            onChange={(datePicked: any) => setDate(datePicked)}
                                                            disabled={!isAdmin}
                                                />
                                            </div>
                                            {isAdmin &&
                                              <div className="form-group">
                                                <label>Payment Method</label>
                                                <select name="ttype" value={payMethod} className="form-control"
                                                        onChange={(e: any) => setPayMethod(e.target.value)}>
                                                  <option value="cash">Cash</option>
                                                  <option value="credit">Credit Card</option>
                                                  <option value="paypal">PayPal</option>
                                                  <option value="other">Other</option>
                                                </select>
                                              </div>
                                            }
                                            <div className="form-group">
                                                <label>Payment Type</label>
                                                <select name="ttype" value={payType} className="form-control"
                                                        onChange={(e: any) => changeMethod(e.target.value)}>
                                                    <option value="renewal">Membership Renewal</option>
                                                    <option value="donation">Donation</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>Payment Amount</label>
                                                <div className="input-group mb-3">
                                                    <span className="input-group-text">$</span>
                                                    <input type="number" className="form-control"
                                                           value={payAmt}
                                                           min="0" step="0.01"
                                                           onChange={(e: any) => setPayAmt(e.target.value)}
                                                           placeholder="0.00"
                                                           aria-label="Amount (to the nearest dollar)"
                                                           disabled={!isAdmin && payType === "renewal"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Notes</label>
                                                <div className="input-group mb-3">
                                                    <textarea onChange={(e: any) => setNotes(e.target.value)}
                                                              value={notes} className="form-control"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {payMethod === 'credit' &&
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <div className="form-group">
                                            <br/>
                                            <label>Credit Card</label>
                                            <div className="form-control" style={{ paddingTop: '6px' }}>
                                              <CardElement options={CARD_ELEMENT_OPTIONS}/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                </form>

                                <div className="wb-modal-footer">
                                    {payType === 'renewal' &&
                                      <div className="alert alert-success"
                                           style={{ fontSize: '16px', position: 'relative', top: '-12px' }}>This
                                        payment will renew your membership through {memberYear}</div>
                                    }
                                    {payMethod === 'credit' &&
                                      <p>By clicking the Submit Payment button, you authorize the Penn Hills CDC to charge your credit
                                        card in the amount of ${ payAmt }. Charges will appear on your statement as PENNHILLSCDC.</p>
                                    }
                                    <button className="btn btn-success"
                                            onClick={() => (payMethod === 'credit' ? getStripeToken() : submitPayment())}
                                            disabled={submitting}> { submitting ? 'Submitting your payment...' : 'Submit Payment' }
                                    </button>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
            </div>

        </>
    )
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
    members: state.members
});

export default connect(mapStateToProps, { getMembers })(PaymentsTable);


import axios from 'axios';

export const setAuthToken = (token:string) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

export const clearAuthToken = () => {
  delete axios.defaults.headers.common['Authorization'];
}

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const GET_PROFILE = 'GET_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';

export const GET_MEMBERS = 'GET_MEMBERS';
export const CLEAR_MEMBER = 'CLEAR_MEMBER';
export const GET_MEMBER_DATA = 'GET_MEMBER_DATA';

export const POST_RENEWAL = 'POST_RENEWAL';

export const SET_PLANS = 'SET_PLANS';
export const CLEAR_PLANS = 'CLEAR_PLANS';

export const GET_PAYMENTS = 'GET_PAYMENTS';
export const CLEAR_PAYMENTS = 'CLEAR_PAYMENTS';


export const INCR_EMAIL_COUNT = 'INCR_EMAIL_COUNT';
export const RESET_EMAIL_COUNT = 'RESET_EMAIL_COUNT';

import React, { useEffect, useState } from 'react';
import { Route, Switch, Link, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { removeAlert } from '../redux/actions/alert';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProfile } from '../redux/actions/profile';
import { loadUser } from '../redux/actions/auth';

import Login from '../screens/auth/Login';
import MainWrapper from '../screens/layout/MainWrapper';
import Dashboard from '../screens/Dashboard';
import Member from '../screens/Member';
import { getPlans } from '../redux/actions/plans';
import Welcome from '../screens/Welcome';
import Register from '../screens/auth/Register';
import MyAccount from '../screens/MyAccount';
import EmailList from '../screens/EmailList';
import PaymentsLog from '../screens/PaymentsLog';
import EmailCampaigns from '../screens/EmailCampaigns';
import Donate from '../screens/Donate';
import Newsletter from '../screens/Newsletter';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKey } from '../services/helpers';
import { Elements } from '@stripe/react-stripe-js';
import PwReset from '../screens/PwReset';
import PwResetForm from '../screens/PwResetForm';

const Router = ( props: any ) => {

    const { removeAlert, auth, profile, plans, loadUser, getPlans } = props;

    const [authChecked, setAuthChecked] = useState( false );

    useEffect( () => {
        (async () => {
            if (!auth.isAuthenticated) await loadUser();
            setAuthChecked( true );
        })();
    }, [auth])

    useEffect( () => {
        (async () => {
            if (!plans.plansLoaded) await getPlans();
        })();
    }, [plans.plansLoaded])

    const stripePromise = loadStripe(stripeKey());

    return (
        <MainWrapper>
            {authChecked &&
              <Elements stripe={stripePromise}>
              <Switch>
                  <PrivateRoute exact path="/member/:id" component={Member}/>
                  <PrivateRoute exact path="/dashboard" component={Dashboard}/>
                  <PrivateRoute exact path="/my-account" component={MyAccount}/>
                  <PrivateRoute exact path="/email-campaigns" component={EmailCampaigns}/>
                  <PrivateRoute exact path="/email-create" component={EmailList}/>
                  <PrivateRoute exact path="/payment-log" component={PaymentsLog}/>
                  <Route exact path="/login" component={Login}/>
                  <Route exact path="/register" component={Register}/>
                  {/*<Route exact path="/donate" component={Donate}/>*/}
                  <Route exact path="/newsletter" component={Newsletter}/>
                  <Route exact path="/pwreset" component={PwReset}/>
                  <Route exact path="/pwreset-form/:hash" component={PwResetForm}/>
                  <Route exact path="/" component={Login}/>
                </Switch>
              </Elements>
            }
        </MainWrapper>
    );
}


const mapStateToProps = (state: any) => ({
    auth: state.auth,
    profile: state.profile,
    plans: state.plans,
});

export default connect(mapStateToProps, { getCurrentProfile, removeAlert, loadUser, getPlans })(Router);
